
/**
 * @name: basic
 * @author: Gzm
 * @date: 2023-05-31 09:40
 * @description：基础信息组件
 * @update: 2023-05-31 09:40
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Message} from "element-ui";
import config from "@/config";
import {queryTourLocationListApi} from "@/apis/travelManage";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Object) data!: Object;
  config = config;
  // 新增修改表单
  modelForm: any = {
    // tourLocationIds: [],
    status: "1"
  }
  // 旅游地址国内
  tourSpecsValueListIn: any = []
  // 旅游地址国外
  tourSpecsValueListOut: any = []


  rules: any = {
    contestName: [
      {required: true, message: '请输入赛事名称', trigger: 'change'},
      {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'change'}
    ],
    enrollQty: [
      {required: true, message: '请输入报名名额', trigger: 'change'},
    ],
    reservePrice: [
      {required: true, message: '请输入预定金额', trigger: 'change'},
    ],
    tourTypes: [
      {required: true, message: '请选择旅游地区', trigger: 'change'}
    ],
    contestBannerPic: [
      {required: true, message: '请上传图片', trigger: 'change'}
    ],
    contestVideo: [
      {required: true, message: '请上传视频', trigger: 'change'}
    ],
    sort: [
      {required: true, message: '请选择排序', trigger: 'change'}
    ],
    endTime: [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          console.log(value,'时间')
          if (value){

          }
          if (!value) {
            return callback(new Error("请选择截止时间"));
          } else if ( value ) {
            let newTime = Date.parse(value.replace(/-/g,'/'))
            let nowTime = new Date().getTime()
            if (newTime <= nowTime){ callback(new Error("截止时间请大于当前日期"));}else {callback();}
          } else {
            callback();
          }
        }, trigger: 'change'
      }
    ],
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * IUpload
   * 文件提交，成功或失败
   * @param res
   */
  onFileUpload(res: any) {
    if (res) {
      console.log(res, 'onFileUpload')
    }

  }

  /**
   * 上传视频前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    let fileSize = file.size / 1024 / 1024 < 50;   //控制大小  修改50的值即可
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) == -1     //控制格式
    ) {
      Message.error("请上传正确的视频格式");
      return false;
    }
    if (!fileSize) {
      Message.error("视频大小不能超过50MB");
      return false;
    }
  }

  /**
   * 下一步
   * @param number
   *
   */
  handleNext(tab: string) {
    console.log(tab)
    this.$emit('Next', tab)
  }

  getSite() {
    queryTourLocationListApi({types: 1}).then(e => {
      this.tourSpecsValueListIn = e
    })
    queryTourLocationListApi({types: 2}).then(e => {
      this.tourSpecsValueListOut = e
    })

  }

  radioTour(e: any) {
    //@ts-ignore
    if (this.modelForm.tourLocationIds.length > 0) {
      this.modelForm.tourLocationIds = []
    }
  }


  submitForm(formName: string) {
    //@ts-ignore
    this.$refs[formName].validate((valid) => {
      if (valid) {
        this.handleNext('second')
        this.$emit('BasicData', this.modelForm, true)
      } else {
        this.$emit('BasicData', this.modelForm, false)
        return false;
      }
    });
  }

  created() {
    // this.getSite()
  }

  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any) {
    this.modelForm = newVal
    this.modelForm.tourTypes = this.modelForm.tourTypes + ""
    this.modelForm.status = this.modelForm.status + ""
    //@ts-ignore
    console.log(this.modelForm.tourLocationIds?.split(';'))
    //@ts-ignore
    this.modelForm.tourLocationIds = this.modelForm.tourLocationIds?.split(';')
  }
}
